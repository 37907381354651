import { ProviderCard } from './provider-card';
import { useProviders } from 'hooks/useProviders';
import styles from './styles.module.css';
import { useMemo } from 'react';
import { MenuTitle } from '../title';
import { Icon } from 'common/icon';
import { SeeMoreCard } from 'components/slider/cards/see-more-card';
import { Routes } from 'constants/routes';

export const MenuProviders = ({ close }: { close: () => void }) => {
  const { results, count } = useProviders();

  const list = useMemo(() => {
    return results?.slice(0, 5);
  }, [results?.length]);

  return (
    <div className={styles.container}>
      <MenuTitle title="Providers" icon={<Icon.Smile />} />

      <div className={styles.row}>
        {list.map((item, index) => {
          const isLast = list?.length - 1 === index;
          return (
            <>
              <ProviderCard
                onClick={close}
                key={item?._id?.toString()}
                data={item}
              />

              {isLast && (
                <SeeMoreCard
                  onClick={close}
                  width={'160px'}
                  count={Number(count) - 5}
                  url={Routes.providers}
                  type={'box'}
                />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
