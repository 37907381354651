import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/icon';
import {
  logos,
  socials,
  listThree,
  footerParams,
  socialListIcons,
  slideSlotBigParams,
} from 'constants/templates';

import { ICasinoCollection, ISlotCollection } from 'constants/types';

import { formatName } from 'utils/formatName';
import { useCasinoCollectionsQuery } from 'store/api/casino_collection';
import { useSlotCollectionsQuery } from 'store/api/slot_collection';

import { useVariablesByNameQuery } from 'store/api/variables';
import { Routes } from 'constants/routes';
import { getCollectionIdentifier } from 'utils/slug';

import styles from './styles.module.css';
import { Analytics } from 'constants/analytics';

export const VarinatTypeMenuList = {
  casino: 'casino',
  slot: 'slot',
  list: 'list',
};

export const Social = ({ item }: { item: footerParams }) => {
  const renderIcon = (icon: string) => {
    switch (icon) {
      case socialListIcons.facebook:
        return <Icon.FacebookSecond />;
      case socialListIcons.telegram:
        return <Icon.Telegram />;
      default:
        return null;
    }
  };

  return (
    <Link
      id={
        (item.text === 'Facebook' && Analytics.general.footerFacebook) ||
        (item.text === 'Telegram' && Analytics.general.footerTelegram) ||
        ''
      }
      target="_blank"
      href={item.link}
      key={item.id.toString()}
      className={styles.footerSocial}
      rel="nofollow noindex"
    >
      {renderIcon(item.icon!)}
      <span>{item.text}</span>
    </Link>
  );
};

export const MenuList = ({
  type,
  item,
}: {
  type: string;
  item: ICasinoCollection | ISlotCollection | footerParams;
}) => {
  const { t } = useTranslation();

  if (type === VarinatTypeMenuList.casino) {
    const casinoItem = item as ICasinoCollection;
    return (
      <li key={casinoItem._id}>
        <Link
          href={Routes.casinoCollections(getCollectionIdentifier(casinoItem))}
          className={styles.footerMenuLink}
        >
          {t(formatName(casinoItem?.name))}
        </Link>
      </li>
    );
  }

  if (type === VarinatTypeMenuList.slot) {
    const slotItem = item as ISlotCollection;
    return (
      <li key={slotItem._id}>
        <Link
          href={Routes.slotCollections(slotItem.slug ?? '')}
          className={styles.footerMenuLink}
        >
          {t(formatName(slotItem?.name))}
        </Link>
      </li>
    );
  }

  if (type === VarinatTypeMenuList.list) {
    const footerItem = item as footerParams;
    return (
      <li key={footerItem.id}>
        <Link href={footerItem.link} className={styles.footerMenuLink}>
          {t(footerItem.text)}
        </Link>
      </li>
    );
  }

  return null;
};

export const Logo = ({ item }: { item: slideSlotBigParams }) => {
  return (
    <div key={item.id.toString()}>
      <Image src={item.img} alt={item.alt} />
    </div>
  );
};

export const Footer = () => {
  const { data: { results: casinosCollections } = {} } =
    useCasinoCollectionsQuery();
  const { data: { results: slotsCollections } = {} } =
    useSlotCollectionsQuery();
  const { data: { value: rtpiArticleLink } = { value: '' } } =
    useVariablesByNameQuery({
      name: 'link-rtpi-article',
    });

  const renderStars = () => {
    return (
      <div className={styles.ratingItems}>
        {Array.from({ length: 5 }).map((_, index) => (
          <div className={styles.ratingItem} key={index?.toString()}>
            {index === 4 ? <Icon.StarHalfFilled /> : <Icon.Star />}
          </div>
        ))}
      </div>
    );
  };

  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.footerSocials}>
          {socials.map((item, index) => (
            <Social key={item.id + index} item={item} />
          ))}
        </div>

        <nav className={styles.footerMenu}>
          <ul className={styles.footerMenuList}>
            {casinosCollections
              ?.filter((it) => it.isVisible)
              ?.slice(0, 5)
              ?.map((item, index) => (
                <MenuList key={item._id + index} type="casino" item={item} />
              ))}
          </ul>
          <ul className={styles.footerMenuList}>
            {slotsCollections
              ?.filter((it) => it.isVisible)
              ?.slice(0, 3)
              ?.map((item, index) => (
                <MenuList key={item?._id + index} type="slot" item={item} />
              ))}
            <MenuList
              type="list"
              item={{ id: 3, link: rtpiArticleLink, text: 'What is RTPi?' }}
            />
            <MenuList
              type="list"
              item={{
                id: 4,
                link: Routes.contactUs,
                text: 'Contact us',
              }}
            />
          </ul>
          <ul className={styles.footerMenuList}>
            {listThree.map((item, index) => (
              <MenuList key={item?.id + index} type="list" item={item} />
            ))}
          </ul>
        </nav>

        <div className={styles.footerLogos}>
          {logos.map((item, index) => (
            <Link
              key={item?.id + index}
              rel="nofollow noindex"
              href={item.link}
              target="_blank"
            >
              <Logo item={item} />
            </Link>
          ))}
        </div>

        <div className={styles.footerReviews}>
          <div className={styles.footerAge}>18+</div>
          <div className={clsx(styles.footerRating, styles.rating)}>
            <div className={styles.ratingBody}>
              <div
                className={clsx(styles.ratingItems, styles.ratingItemsActive)}
              >
                {renderStars()}
              </div>
            </div>
            <div className={styles.ratingTotal}>
              <span className={styles.ratingValue}>4.7</span>
              <span>out of 5</span>
            </div>
          </div>

          <div className={styles.footerRaitingAmount}>72 customers ratings</div>
        </div>

        <div className={styles.footerCopy}>© 2017-2024 by RTPi.info</div>
      </div>
    </footer>
  );
};
