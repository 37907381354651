import { ICasino } from 'constants/types';
import { useMemo } from 'react';
import { useCasinoByIdQuery } from 'store/api/casino';

type Props = {
  skip?: boolean;
  casino_ids: string[] | string | undefined;
};

export const useCasinosByIds = ({ casino_ids, skip }: Props) => {
  const { data, isFetching } = useCasinoByIdQuery(
    {
      id: casino_ids?.toString() ?? '',
    },
    { skip: !casino_ids || skip }
  );

  const { casinos, count } = useMemo(() => {
    if (typeof casino_ids === 'string') {
      return { casinos: [data] as ICasino[], count: 1 };
    }
    if (casino_ids?.length === 1) {
      return { casinos: [data] as ICasino[], count: 1 };
    }
    return {
      casinos: (data?.results ?? []) as ICasino[],
      count: data?.count ?? 0,
    };
  }, [data]);

  return { casinos, isFetching, count };
};
