import { useCasinosQuery } from 'store/api/casino';
import { useCurrentRegion } from './useCurrentRegion';

type Props = {
  skip?: boolean;
  collection?: string;
};

export const useCasinos = (props?: Props) => {
  const { collection, skip } = props ?? {};

  const { isFetching: isCountriesFetching, currentRegion } = useCurrentRegion();

  const { data: { results: casinos = [], count = 0 } = {}, isFetching } =
    useCasinosQuery(
      {
        collections: collection,
        countries: currentRegion?._id,
      },
      { skip: isCountriesFetching || skip }
    );

  return {
    count,
    casinos,
    isFetching,
  };
};
