import { useEffect } from 'react';
import { useMeQuery } from 'store/api/users';

export const useGoogleTagManagerUserId = () => {
  const { data: me } = useMeQuery();
  const { user } = me ?? {};

  useEffect(() => {
    if (user?._id) {
      if (typeof window !== 'undefined' && user && user._id) {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({
          user_id: user._id,
        });
      }
    }
  }, [user]);

  return null;
};
