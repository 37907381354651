import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  clearCasinoReviewsPagination,
  selectPaginationCasinoReviews,
  selectPaginationCasinoReviewsOptions,
  setPaginationCasinoReviewsCurrentId,
  useLazyPaginationCasinoReviewsQuery,
} from 'store/api/pagination';

export const useCasinoReviewsPagination = ({
  casinoId,
}: {
  casinoId: string | string[] | undefined;
}) => {
  const dispatch = useAppDispatch();

  const limit = 12;

  const [fetchData, { data: { count = -1 } = {}, isFetching }] =
    useLazyPaginationCasinoReviewsQuery();

  const { currentId } = useSelector(selectPaginationCasinoReviewsOptions());
  const data = useSelector(selectPaginationCasinoReviews());

  const [page, setPage] = useState(
    data?.length ? Math.ceil(data?.length / limit) : 1
  );

  useEffect(() => {
    if (currentId !== casinoId) {
      setPage(1);
      dispatch(clearCasinoReviewsPagination());
      dispatch(setPaginationCasinoReviewsCurrentId(casinoId));
    }
  }, [currentId, casinoId]);

  useEffect(() => {
    const isCached =
      casinoId === currentId &&
      data?.length &&
      Math.ceil(data?.length / limit) === page;

    if (!isCached && casinoId) {
      fetchData({ id: casinoId?.toString() ?? '', page, limit });
    }
  }, [limit, page, casinoId]);

  const isLast = useMemo(() => {
    if (Number(count) === -1) {
      return false;
    }
    return data?.length >= Number(count);
  }, [count, data?.length]);

  return {
    page,
    data,
    isLast,
    setPage,
    isFetching,
    count: Number(count) < 0 ? 0 : count,
  };
};
