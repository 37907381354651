import { useSelector } from 'react-redux';
import { selectRegion } from 'store/slices/settings';

import { IRegionItem } from 'constants/types';
import { useCountriesQuery } from 'store/api/countries';

export const useCurrentRegion = () => {
  const region = useSelector(selectRegion());
  const { data: countries, isFetching: isFetching } = useCountriesQuery();

  const selectedRegionById = countries?.find(
    (item: IRegionItem) => item._id === region
  );

  const selectedRegionByCode = countries?.find(
    (item: IRegionItem) => item.code === region
  );

  const currentRegion = selectedRegionById ?? selectedRegionByCode;

  return {
    isFetching,
    currentRegion,
  };
};
