import { SlotSorting, useSlotsQuery } from 'store/api/slots';

type Props = {
  collection?: string;
  limit?: number;
  skip?: boolean;
  sort?: SlotSorting;
};

export const useSlots = (props?: Props) => {
  const { collection, limit, skip, sort } = props ?? {};

  const { data: { results: slots = [], count } = {}, isFetching } =
    useSlotsQuery({ sort, collections: collection, limit }, { skip });

  return {
    slots,
    count,
    isFetching,
  };
};
