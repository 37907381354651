import clsx from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'next/router';

import { Icon } from 'common/icon';
import { ICasino, IProvider, ISlot } from 'constants/types';

import styles from './styles.module.css';
import { LogoOverlay, LogoOverlayWrapper } from 'components/logo-overlay';
import { Routes } from 'constants/routes';
import { useRTPiWeights } from 'hooks/useRTPiWeights';

interface SearchCardProps {
  item: ICasino | ISlot | IProvider;
  handleClose: () => void;
}

export const SearchNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.search__no_result}>
      <div className={styles.search__no_result_top}>
        <Icon.ExclamationMark fill="white" />

        <span>{t('No results')}</span>
      </div>
      <div className={styles.search__no_result_bottom}>
        <div className={styles.search__no_result_question}>
          {t(`Can't find your casino or slot?`)}
        </div>
        <Link
          href={Routes.contactUs}
          className={styles.search__no_result_contact}
        >
          <span>{t('contact us')}</span>
          <Icon.Arrow />
        </Link>
      </div>
    </div>
  );
};

export const SearchCard = ({ item, handleClose }: SearchCardProps) => {
  const router = useRouter();

  const isCasino = 'rating' in item;
  const isProvider = 'slotsCount' in item;

  const handleCard = (id: string) => {
    if (isCasino) {
      router.push(Routes.casinoPage(id));
    } else {
      router.push(Routes.slotPage(id));
    }
    handleClose();
  };

  const { isBad, isGood, isFetching: isRatingFetching } = useRTPiWeights();

  const renderIcon = (rating: number) => {
    if (isRatingFetching) {
      return <Icon.Cherry fill="#FEFEFE" />;
    }
    if (isBad(rating)) {
      return <Icon.Bad />;
    } else if (isGood(rating)) {
      return <Icon.Good />;
    } else {
      return <Icon.Cherry />;
    }
  };

  return (
    <div className={styles.search__result} onClick={() => handleCard(item._id)}>
      <div className={styles.search__result_card}>
        <div className={styles.search__result_img}>
          <LogoOverlayWrapper>
            {'logoUrl' in item && (
              <LogoOverlay
                width={70}
                height={70}
                alt={item?.name}
                image={item?.logoUrl ?? ''}
              />
            )}
            <div
              className={clsx({
                [styles.providerImage]: isProvider,
              })}
            >
              <Image
                src={item?.imageUrl ?? ''}
                width={70}
                height={70}
                alt={item?.name}
              />
            </div>
          </LogoOverlayWrapper>
        </div>
        <div className={styles.search__result_info}>
          <div className={styles.search__result_name}>{item?.name}</div>
          <div className={styles.search__result_rtp}>
            {isCasino && (
              <div className={styles.casino_card__rtp}>
                {renderIcon(parseInt(item.rating))}
                <span>{item.rating}%</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.search__result_inpsections}>
        <Icon.Search />
        {!isProvider && (
          <span>
            {(('casinos_reviews_count' in item
              ? item?.casinos_reviews_count
              : 0) ?? 0) +
              ('slots_reviews_count' in item
                ? item?.slots_reviews_count ?? 0
                : 0)}{' '}
            inspections
          </span>
        )}
        {isProvider && <span>{`${item?.slotsCount ?? 0} slots`}</span>}
      </div>
      <div className={styles.search__result_arrow}>
        <Icon.Arrow />
      </div>
    </div>
  );
};

export const Search = ({
  data,
  handleClose,
}: {
  data: Array<ICasino | ISlot | IProvider>;
  handleClose: () => void;
}) => {
  return (
    <div className={clsx(styles.header__search, styles.search)}>
      <div className={styles.search__results}>
        <div className={styles.scroll}>
          <div className={styles.search__results_wrapper}>
            {data && data.length > 0 ? (
              data.map((card: ICasino | ISlot | IProvider) => (
                <SearchCard
                  item={card}
                  handleClose={handleClose}
                  key={card._id.toString()}
                />
              ))
            ) : (
              <SearchNotFound />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
