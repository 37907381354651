export function formatName(name: string) {
  let formattedName = '';

  if (typeof name === 'string') {
    const words = name.split('-');
    formattedName = words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  return formattedName;
}
