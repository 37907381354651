import Image from 'next/image';

import styles from './styles.module.css';

type Props = {
  alt?: string;
  image?: string;
  width: number;
  height: number;
};

export const LogoOverlayWrapper = ({ children }: React.PropsWithChildren) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export const LogoOverlay = ({ alt, image, width, height }: Props) => {
  if (!image) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Image
        src={image}
        width={width}
        height={height}
        style={{ width, height }}
        alt={alt ?? image + 'logo'}
      />
    </div>
  );
};
