import { RefObject, useMemo } from 'react';

import { ICasinoCollection, ISlotCollection } from 'constants/types';
import { useOpenTriggerState } from 'hooks/useOpenTrigger';

import { Routes } from 'constants/routes';
import { getCollectionIdentifier } from 'utils/slug';

import Link from 'next/link';
import styles from './styles.module.css';

export interface Props {
  state?: useOpenTriggerState;
  triggerRef?: RefObject<any>;
}

export const MenuLinkItem = ({
  item,
  type,
  close,
  customUrl,
}: {
  customUrl?: string;
  close: () => void;
  type: 'casino' | 'slot';
  item: ICasinoCollection | ISlotCollection | { name: string; _id: string };
}) => {
  const url = useMemo(() => {
    if (customUrl) {
      return customUrl;
    }
    switch (type) {
      case 'casino':
        return Routes.casinoCollections(getCollectionIdentifier(item));
      case 'slot':
        return Routes.slotCollections(getCollectionIdentifier(item));
      default:
        return Routes.casinoCollections(getCollectionIdentifier(item));
    }
  }, [type, item?.name]);

  return (
    <li
      key={item._id}
      className={styles.container}
      onClick={() => {
        close();
      }}
    >
      <Link className={styles.innerContainer} href={url}>
        {item.name}
      </Link>
    </li>
  );
};
