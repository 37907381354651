import { useCasinos } from './useCasinos';

export const useTrustedCasinos = () => {
  const {
    casinos: trustedCasinos,
    isFetching: isTrustedCasinosFetching,
    count: trustedCasinosCount,
  } = useCasinos({ collection: 'Trusted Casinos' });

  return {
    trustedCasinos,
    trustedCasinosCount,
    isTrustedCasinosFetching,
  };
};
