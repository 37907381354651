import { Routes } from 'constants/routes';
import { IRegionItem } from 'constants/types';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { useCountriesQuery } from 'store/api/countries';
import { setRegionModalRequired } from 'store/slices/regions';
import { selectRegion, setRegion } from 'store/slices/settings';
import { getCountryByIP } from 'utils/ip';
import {
  getIsRegionManuallySelected,
  saveCurrentRegion,
  setRegionManuallySelected,
} from 'utils/storage';
import styles from './styles.module.css';
import { isMobile } from 'hooks';
import { Analytics } from 'constants/analytics';

const CountryTag = ({ country }: { country?: IRegionItem | undefined }) => {
  if (!country) {
    return null;
  }

  return (
    <div className={styles.countryContainer}>
      <div className={styles.regionContainer}>
        <Image
          src={country.imageUrl ?? ''}
          alt={country.name}
          width={20}
          height={20}
        />
        <span>{country.name}</span>
      </div>
    </div>
  );
};

export const RegionAutodetect = () => {
  const mobile = isMobile();

  const dispatch = useAppDispatch();
  const [isLoaded, setLoaded] = useState(false);
  const [isHideBlocked, setHideBlocked] = useState(false);
  const currentRegion = useSelector(selectRegion());
  const [isOpened, setOpened] = useState(false);
  const [isVisible, setVisible] = useState(false);

  const isRegionManuallyEntered = getIsRegionManuallySelected();

  const [detectedCountry, setDetectedCountry] = useState<string | null>(null);

  const { data: countries, isFetching } = useCountriesQuery();

  useEffect(() => {
    const handle = async () => {
      const result = await getCountryByIP();

      const country = countries?.find(
        (item) =>
          item.slug?.toLowerCase() === result?.country_code?.toLowerCase()
      );
      if (country) {
        setDetectedCountry(country?._id);
      }
      setLoaded(true);
      setOpened(true);
    };

    if (!isFetching) {
      handle();
    }
  }, [isFetching]);

  useEffect(() => {
    const handle = async () => {
      setTimeout(() => {
        setVisible(isOpened && !(currentRegion && currentRegion !== 'null'));
      }, 500);
    };

    handle();
  }, [isOpened, currentRegion]);

  useEffect(() => {
    if (detectedCountry) {
      const region = countries?.find((item) => item._id === detectedCountry);
      if (currentRegion !== region?._id) {
        setHideBlocked(true);
      }

      // saveCurrentRegion(region?._id);
      // dispatch(setRegion(region?._id));
    }
  }, [detectedCountry]);

  if (isRegionManuallyEntered) {
    return;
  }

  if (!isLoaded) {
    return null;
  }

  if (!isVisible && !isHideBlocked) {
    return null;
  }

  if (mobile) {
    return (
      <div className={styles.wrapper}>
        <div
          className={`${styles.container} ${!isOpened ? styles.closed : ''}`}
        >
          <div className={styles.leftContainer}>
            <div className={styles.textContainer}>
              {!detectedCountry && (
                <div className={styles.unDetectedRegionContainer}>
                  <span className={styles.text}>
                    We couldn’t autodetect your region with{' '}
                    <Link href={Routes.cookie} className={styles.textUnderline}>
                      cookie policy
                    </Link>
                  </span>
                </div>
              )}
              {detectedCountry && (
                <div className={styles.detectedRegionContainer}>
                  <CountryTag
                    country={countries?.find(
                      (item) => item._id === detectedCountry
                    )}
                  />
                  <span className={styles.textContainer}>
                    {`We autodected your region with`}
                    <Link href={Routes.cookie} className={styles.textUnderline}>
                      cookie policy
                    </Link>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={styles.rightContainer}>
            <button
              id={
                detectedCountry
                  ? Analytics.banner.regionAccept
                  : Analytics.banner.regionChange
              }
              onClick={() => {
                if (detectedCountry) {
                  setHideBlocked(false);
                  saveCurrentRegion(detectedCountry);
                  dispatch(setRegion(detectedCountry));
                }
                if (!detectedCountry) {
                  dispatch(setRegionModalRequired(true));
                }

                setRegionManuallySelected(true);
                setOpened(false);
              }}
              className={styles.button}
            >
              {detectedCountry ? 'Okay, accept' : 'Choose region'}
            </button>
            <button
              id={
                detectedCountry
                  ? Analytics.banner.regionChange
                  : Analytics.banner.regionClose
              }
              onClick={() => {
                if (detectedCountry) {
                  dispatch(setRegionModalRequired(true));
                }

                setOpened(false);
              }}
              className={styles.closeButton}
            >
              {detectedCountry ? 'Change Region' : 'Close'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} ${!isOpened ? styles.closed : ''}`}>
        <div className={styles.leftContainer}>
          <div className={styles.textContainer}>
            {!detectedCountry && (
              <span className={styles.text}>
                We couldn’t autodetect your region with{' '}
                <Link href={Routes.cookie} className={styles.textUnderline}>
                  cookie policy
                </Link>
              </span>
            )}
            {detectedCountry && (
              <span className={styles.textContainer}>
                {`We autodected your region as`}
                <div className={styles.detectedRegionContainer}>
                  <CountryTag
                    country={countries?.find(
                      (item) => item._id === detectedCountry
                    )}
                  />
                </div>
                {` with `}
                <Link href={Routes.cookie} className={styles.textUnderline}>
                  cookie policy
                </Link>
              </span>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <button
            id={
              detectedCountry
                ? Analytics.banner.regionAccept
                : Analytics.banner.regionChange
            }
            onClick={() => {
              if (detectedCountry) {
                setHideBlocked(false);
                saveCurrentRegion(detectedCountry);
                dispatch(setRegion(detectedCountry));
              }
              if (!detectedCountry) {
                dispatch(setRegionModalRequired(true));
              }

              setRegionManuallySelected(true);
              setOpened(false);
            }}
            className={styles.button}
          >
            {detectedCountry ? 'Okay, accept' : 'Okay, choose region'}
          </button>
          <button
            id={
              detectedCountry
                ? Analytics.banner.regionChange
                : Analytics.banner.regionClose
            }
            onClick={() => {
              if (detectedCountry) {
                dispatch(setRegionModalRequired(true));
              }

              setOpened(false);
            }}
            className={styles.closeButton}
          >
            {detectedCountry ? 'Change Region' : 'Close'}
          </button>
        </div>
      </div>
    </div>
  );
};
