import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export interface Props {
  icon: JSX.Element;
  title: string;
}

export const MenuTitle = ({ icon, title }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.menuTitle}>
      {icon}
      <span>{t(title)}</span>
    </div>
  );
};
