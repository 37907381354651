import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { RefObject } from 'react';

import styles from './styles.module.css';

interface ModalProps {
  title: string;
  width?: number;
  footer: React.ReactNode;
  isVisible: boolean;
  isInfo?: boolean;
  targetRef?: RefObject<HTMLDivElement> | null;
  children: React.ReactNode;
  closeOnClick?: () => void;
}

export const ModalMain = ({
  width,
  title,
  isInfo,
  footer,
  children,
  isVisible,
  targetRef,
}: ModalProps) => {
  const { t } = useTranslation();

  const containerClasses = clsx(styles.container, {
    [styles.visible]: isVisible,
  });
  const wrapperClasses = clsx(styles.wrapper, {
    [styles.visible]: isVisible,
  });

  return (
    <div className={containerClasses}>
      <div
        ref={targetRef}
        className={wrapperClasses}
        style={{ flexBasis: width ?? 612 }}
      >
        <div className={styles.wrapper_header}>
          <span className={styles.title}>{t(title)}</span>
        </div>
        <div className={styles.body}>{children}</div>
        <div className={`footer ${isInfo ? 'info' : ''}`}>{footer}</div>
      </div>
    </div>
  );
};
