export const Analytics = {
  auth: {
    email: 'auth-email',
    facebook: 'auth-facebook',
    google: 'auth-google',
  },
  banner: {
    allRegionsRegion: (region: string) => `bann-allregions-${region}`,
    loginCancel: 'bann-login-cancel',
    loginLogin: 'bann-login-login',
    regionAccept: 'bann-region-accept',
    regionChange: 'bann-region-change',
    regionClose: 'bann-region-close',
  },
  casino: {
    allReviews: 'cas-all-playerreviews',
    allRtpReviews: 'cas-all-rtpreviews',
    similarCasinos: 'cas-coll-similar',
    allInspections: 'cas-hero-allinspections',
    howToChoose: 'cas-hero-guide-howtochoose',
    inspect: 'cas-hero-inspect',
    play: 'cas-hero-play',
    anchorPlayerReviews: 'cas-hero-playerreviews',
    anchorRtpReviews: 'cas-hero-rtpreviews',
    save: 'cas-hero-save',
    unsave: 'cas-hero-unsave',
    inspectAddReview: 'cas-insp-addreview',
    inspectEditReview: 'cas-insp-editreview',
    inspectAddSlotRtpReview: 'cas-insp-addrtpreview',
    inspectAllBigWins: 'cas-insp-bigwins',
    inspectAllPlayerReviews: 'cas-insp-playerreviews',
    inspectAllSlotsRtpReviews: 'cas-insp-rtpreviews',
  },
  collections: {
    guide: 'coll-hero-guide',
  },
  general: {
    footerFacebook: 'gen-footer-soc-facebook',
    footerTelegram: 'gen-footer-soc-telegram',
    headerLogin: 'gen-header-login',
    headerMenu: 'gen-header-menu',
    headerRegion: 'gen-header-region',
    headerSaved: 'gen-header-saved',
  },
  home: {
    createAccount: 'home-but-createacc',
    openCollectionCard: 'home-card-opencollection',
  },
  provider: {
    guide: 'prov-card-guide-provider',
  },
  reviews: {
    dislike: 'rev-card-dislike',
    like: 'rev-card-like',
    userPage: 'rev-card-userpage',
    addReview: 'rev-hero-addreview',
    visitCasinoPage: 'rev-hero-caspage',
  },
  rtp: {
    guideHowToAddRtp: 'rtp-card-guide-howaddrtp',
    slotPage: 'rtp-card-slotpage',
    userPage: 'rtp-card-userpage',
    addRtpReview: 'rtp-hero-addrtpreview',
    visitCasinoPage: 'rtp-hero-caspage',
  },
  slot: {
    providerGuide: 'slot-card-guide-provider',
    allInspections: 'slot-hero-allinspections',
    inspect: 'slot-hero-inspect',
    providerPage: 'slot-hero-provider',
    save: 'slot-hero-save',
    unsave: 'slot-hero-unsave',
  },
  user: {
    editAvatar: 'user-edit-avatar',
    editEmail: 'user-edit-email',
    editLinkFacebook: 'user-edit-link-facebook',
    editLinkGoogle: 'user-edit-link-google',
    editUsername: 'user-edit-username',
    editProfile: 'user-hero-editprofile',
    logout: 'user-hero-logout',
  },
};
