import clsx from 'clsx';
import { Icon } from 'common/icon';
import styles from './styles.module.css';

type Props = {
  isOpen?: boolean;
  type?: 'solid' | 'line';
};

export const SelectArrow = ({ isOpen, type = 'line' }: Props) => {
  return (
    <div
      className={clsx(styles.arrow, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
      })}
    >
      {type === 'line' && <Icon.ArrowLeft />}
      {type === 'solid' && <Icon.ArrowSolid />}
    </div>
  );
};
