import clsx from 'clsx';
import { useOpenTrigger } from 'hooks/useOpenTrigger';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IRegionItem } from 'constants/types';
import { useCountriesQuery } from 'store/api/countries';

import { Icon } from 'common/icon';
import { SelectArrow } from 'components/modal-arrow';
import { Analytics } from 'constants/analytics';
import { Routes } from 'constants/routes';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsRegionModalRequired,
  setRegionModalRequired,
} from 'store/slices/regions';
import { selectRegion, setRegion } from 'store/slices/settings';
import { useOnClickOutside } from 'usehooks-ts';
import {
  clearRefferer,
  clearUtm,
  saveCurrentRegion,
  saveRefferer,
  setRegionManuallySelected,
} from 'utils/storage';
import styles from './styles.module.css';

const AllRegionsItem = {
  image: null,
  name: 'All Regions',
  code: null,
  _id: null,
  slug: null,
  imageUrl:
    'https://d11o05pl2tdcd2.cloudfront.net/countries/1708125566357-918795276-nmez-rrl1.webp',
} as IRegionItem;

const Modal = (state: any) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    if (state.state.close) {
      state.state.close();
    }
  };

  useOnClickOutside(modalRef, handleClose, 'mouseup');

  const { data = [] } = useCountriesQuery();

  const currentRegion = useSelector(selectRegion());

  const handleLanguageChange = ({ _id }: { _id: string | null }) => {
    saveCurrentRegion(_id);
    dispatch(setRegion(_id));
    setRegionManuallySelected(true);
    if (state.state.close) {
      state.state.close();
    }
    if (router.pathname === '/') {
      const { reg, ...query } = router.query;

      router.replace(
        {
          pathname: router.pathname,
          query: _id
            ? {
                ...query,
                reg: data?.find((item) => item._id === _id)?.slug || _id,
              }
            : query,
        },
        undefined,
        { shallow: false }
      );
    }
  };

  const checkSelected = ({ item }: { item: IRegionItem }) => {
    if (currentRegion === item._id) {
      return true;
    }
    if (currentRegion === null && item.name === AllRegionsItem.name) {
      return true;
    }
    if (currentRegion === 'null' && item.name === AllRegionsItem.name) {
      return true;
    }

    return false;
  };

  const regionsList = useMemo(() => {
    return [AllRegionsItem, ...data]?.map((item: IRegionItem, index) => (
      <li
        id={Analytics.banner.allRegionsRegion(item.slug ?? 'null')}
        key={(item?._id ?? 'null')?.toString() + index}
        className={clsx(styles.regionsItem, {
          [styles.regionsItemActive]: checkSelected({ item }),
        })}
        onClick={() => handleLanguageChange(item)}
      >
        {item?.imageUrl && (
          <Image src={item?.imageUrl} width={21} height={15} alt={item.name} />
        )}
        <span>{t(item.name)}</span>
      </li>
    ));
  }, [currentRegion]);

  return (
    <div
      className={clsx(styles.regionsBody, {
        [styles.regionsBodyActive]: state.state.isOpen,
      })}
    >
      <div className={styles.container}>
        <div className={styles.regionsWrapper} ref={modalRef}>
          <div className={styles.regionsTop}>
            <div className={styles.regionsTitle}>
              <span>{t('Regions')}</span>
            </div>
            <div
              className={styles.regionsCloseButton}
              onClick={state.state.close}
            >
              <Icon.Close />
            </div>
          </div>
          <div className={styles.regionsContainer}>
            <ul className={styles.regionsItems}>{regionsList}</ul>
            <p className={styles.cookieAgreementText}>
              When you change region, you accept{' '}
              <Link
                href={Routes.cookie}
                className={clsx(
                  styles.cookieAgreementText,
                  styles.cookieAgreementLink
                )}
              >
                cookie policy
              </Link>
            </p>
          </div>
          <div className={styles.regionsBottom}>
            <div className={styles.regionsFindLoc}>
              {t('Can find your location?')}
            </div>

            <span
              className={styles.regionsContact}
              onClick={() => router.push(Routes.contactUs)}
            >
              {t('contact us')}
              <Icon.Arrow />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RegionsModal = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const [isButtonOverlayed, setButtonOverlayed] = useState(false);

  const isRegionModalRequired = useSelector(selectIsRegionModalRequired());

  const { data, isFetching } = useCountriesQuery();

  const [isOpen, setOpen] = useState(false);
  const state = useOpenTrigger({ isOpen, setOpen });
  const selectedRegion = useSelector(selectRegion());

  const current = useMemo(() => {
    return (
      data?.find((item: IRegionItem) => item._id === selectedRegion) ||
      data?.find((item: IRegionItem) => item.slug === selectedRegion) ||
      AllRegionsItem
    );
  }, [selectedRegion, data]);

  useEffect(() => {
    if (isRegionModalRequired) {
      setOpen(true);
      dispatch(setRegionModalRequired(false));
    }
  }, [isRegionModalRequired]);

  useEffect(() => {
    if (router.query.reg) {
      const region = data?.find(
        (item: IRegionItem) => item.slug === router.query.reg
      );

      if (region) {
        saveCurrentRegion(region._id);
        dispatch(setRegion(region._id));

        if (router.pathname !== '/') {
          const { reg, ...query } = router.query;
          router.replace(
            {
              pathname: router.pathname,
              query,
            },
            undefined,
            { shallow: false }
          );
        }
      }
    }
  }, [router?.query?.reg, data]);

  // Save Session Source
  useEffect(() => {
    const referrer = document.referrer;

    clearRefferer();

    if (referrer) {
      saveRefferer(referrer);
    }
  }, []);

  // Save UTM
  useEffect(() => {
    if (
      router.query.utm_source ||
      router.query.utm_medium ||
      router.query.utm_campaign ||
      router.query.utm_content ||
      router.query.utm_term
    ) {
      const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
        ...query
      } = router.query;

      clearUtm();

      const utmRecord: Record<string, string> = {};
      if (utm_source) {
        utmRecord.utm_source = utm_source?.toString();
      }
      if (utm_medium) {
        utmRecord.utm_medium = utm_medium?.toString();
      }
      if (utm_campaign) {
        utmRecord.utm_campaign = utm_campaign?.toString();
      }
      if (utm_content) {
        utmRecord.utm_content = utm_content?.toString();
      }
      if (utm_term) {
        utmRecord.utm_term = utm_term?.toString();
      }

      router.replace(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: false }
      );
    }
  }, [
    router.query.utm_source,
    router.query.utm_medium,
    router.query.utm_campaign,
    router.query.utm_content,
    router.query.utm_term,
  ]);

  useEffect(() => {
    if (!router.query.reg && selectedRegion && current && current._id) {
      if (router.pathname === '/') {
        router.replace(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              reg: current?.slug ?? '',
            },
          },
          undefined,
          { shallow: false }
        );
      }
    }
  }, [data, current]);

  useEffect(() => {
    if (state.isOpen) {
      setButtonOverlayed(true);
    } else {
      setTimeout(() => {
        setButtonOverlayed(false);
      }, 500);
    }
  }, [state.isOpen]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <button
        className={styles.regionsButton}
        onClick={isButtonOverlayed ? state.close : state.open}
      >
        {current?.imageUrl && (
          <Image
            width={21}
            height={15}
            alt={current.name}
            src={current?.imageUrl}
          />
        )}
        <span className={styles.regionName}>{t(current?.name)}</span>
        <span className={styles.regionSlug}>{t(current?.slug ?? '')}</span>
        <div className={styles['arrow-container']}>
          <SelectArrow type="solid" isOpen={false} />
        </div>
      </button>

      <Modal state={state} />
    </>
  );
};
