import { useState } from 'react';
import { FormikValues } from 'formik';

export const useStep = (
  handleInfo: (email: string, isChange?: boolean) => Promise<void>,
  handleChangePassword: () => void
) => {
  const [formValues, setFormValues] = useState<FormikValues>({});
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    { title: 'Information', function: handleInfo },
    { title: 'Change Password', function: handleChangePassword },
  ];

  const handleNextStep = () => {
    const nextStep = currentStep + 1;

    if (nextStep < steps.length) {
      setCurrentStep(nextStep);
    } else {
      setCurrentStep(0);
    }
  };

  const getCurrentStepFunction = () => {
    return steps[currentStep]?.function || (() => {});
  };

  const saveFormValues = (values: FormikValues) => {
    setFormValues(values);
  };

  return {
    formValues,
    currentStep,
    saveFormValues,
    handleNextStep,
    getCurrentStepFunction,
  };
};
