import { useCallback } from 'react';
import { useAlert } from 'components';

import {
  useFacebookLinkMutation,
  useFacebookUnlinkMutation,
  useGoogleLinkMutation,
  useGoogleUnlinkMutation,
} from 'store/api/auth';
import { handleMutation } from 'utils/handleMutation';

export const useSocialAccounts = () => {
  const alert = useAlert();

  const [linkGoogle, linkGoogleMutation] = useGoogleLinkMutation();
  const [unlinkGoogle, unlinkGoogleMutation] = useGoogleUnlinkMutation();

  const [linkFacebook, linkFacebookMutation] = useFacebookLinkMutation();
  const [unlinkFacebook, unlinkFacebookMutation] = useFacebookUnlinkMutation();

  const isUnlinkGoogleLoading = unlinkGoogleMutation.isLoading;
  const isUnlinkFacebookLoading = unlinkFacebookMutation.isLoading;

  const onLinkGoogleSuccess = useCallback(() => {
    alert.success('Google account successfully linked');
  }, []);

  const onLinkGoogleError = useCallback(() => {
    alert.error('Google account link error');
  }, []);

  const onUnlinkGoogleSuccess = useCallback(() => {
    alert.success('Google account successfully unlinked');
  }, []);

  const onUnlinkGoogleError = useCallback(() => {
    alert.error('Google account unlink error');
  }, []);

  const onLinkFacebookSuccess = useCallback(() => {
    alert.success('Facebook account successfully linked');
  }, []);

  const onLinkFacebookError = useCallback(() => {
    alert.error('Facebook account link error');
  }, []);

  const onUnlinkFacebookSuccess = useCallback(() => {
    alert.success('Facebook account successfully unlinked');
  }, []);

  const onUnlinkFacebookError = useCallback(() => {
    alert.error('Facebook account unlink error');
  }, []);

  handleMutation({
    ...linkGoogleMutation,
    onSuccess: onLinkGoogleSuccess,
    onError: onLinkGoogleError,
  });

  handleMutation({
    ...unlinkGoogleMutation,
    onSuccess: onUnlinkGoogleSuccess,
    onError: onUnlinkGoogleError,
  });

  handleMutation({
    ...linkFacebookMutation,
    onSuccess: onLinkFacebookSuccess,
    onError: onLinkFacebookError,
  });

  handleMutation({
    ...unlinkFacebookMutation,
    onSuccess: onUnlinkFacebookSuccess,
    onError: onUnlinkFacebookError,
  });

  return {
    linkGoogle,
    unlinkGoogle,
    linkFacebook,
    unlinkFacebook,
    isUnlinkGoogleLoading,
    isUnlinkFacebookLoading,
  };
};
