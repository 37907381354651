import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'store/slices/auth';
import { useDebounce } from 'usehooks-ts';

import { Icon } from 'common/icon';
import { Menu } from 'components/menu';
import { LoginModal } from 'components/modal/login';
import { RegionsModal } from 'components/modal/regions';

import CherriesIcon from 'assets/icons/cherries.svg';
import SearchIcon from 'assets/icons/search-main.svg';

import { ResultSearch } from './result-search';

import { Routes } from 'constants/routes';
import { useLoginModal } from 'hooks';
import Link from 'next/link';
import { useMeQuery } from 'store/api/users';

import { useFocus } from 'hooks/useFocus';
import styles from './styles.module.css';
import { RegionAutodetect } from 'components/region-autodetect';
import { Analytics } from 'constants/analytics';
import { useGoogleTagManagerUserId } from 'hooks/useGoogleTagManagerUserId';

const Logo = ({ isMain = false }: { isMain?: boolean }) => {
  return isMain ? (
    <div className={clsx(styles.headerLogo, styles.default_cursor)}>
      <Image src={CherriesIcon} alt="cherries-icon" />
      <span>RTPi</span>
    </div>
  ) : (
    <Link href={Routes.home} className={styles.headerLogo}>
      <Image src={CherriesIcon} alt="cherries-icon" />
      <span>RTPi</span>
    </Link>
  );
};

export const Header = ({ isMain = false }: { isMain?: boolean }) => {
  const router = useRouter();
  const search = router.query.q
    ? String(decodeURIComponent(router.query.q?.toString()))
    : '';

  const { t } = useTranslation();
  const searchRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isLoggedIn = useSelector(selectIsLoggedIn());
  const { data: me } = useMeQuery();
  const { user } = me ?? {};

  const [filter, setFilter] = useState('');

  const debounceValue = useDebounce(filter, 500);
  const isFocused = useFocus(searchRef);

  useGoogleTagManagerUserId();

  useEffect(() => {
    if (search) {
      setFilter(search);
      searchRef.current?.focus();
    }
  }, [search]);

  useEffect(() => {
    const currentPathname = router.pathname;

    if (currentPathname === Routes.home) {
      if (debounceValue?.length) {
        router.push(
          `${Routes.home}?q=${encodeURIComponent(debounceValue)}`,
          undefined,
          {
            shallow: true,
          }
        );
      }
      if (debounceValue?.length === 0 && isFocused) {
        router.push(Routes.home, undefined, {
          shallow: true,
        });
      }
    }
  }, [debounceValue]);

  const {
    handleCloseLoginModal,
    handleConfirmLoginModal,
    handleVisibleLoginModal,
    isLoginVisible,
  } = useLoginModal();

  const handleSaved = () => {
    router.push('/profile');
  };

  const handleProfile = useCallback(() => {
    if (isLoggedIn) {
      router.push('/profile');
    } else {
      router.push('/login');
    }
  }, [isLoggedIn]);

  return (
    <div suppressHydrationWarning={true}>
      <header className={styles.header}>
        <div className={styles.headerInner}>
          <div className={styles.container}>
            <div id={Analytics.general.headerMenu} className={styles.headerRow}>
              <Logo isMain={isMain} />

              <Menu />

              <div className={clsx(styles.headerSearch, styles.search)}>
                <div className={styles.wrapperButton}>
                  <button className={styles.searchButton}>
                    <Image src={SearchIcon} alt="search-icon" />
                  </button>
                  <input
                    type="text"
                    value={filter}
                    ref={searchRef}
                    className={styles.searchInput}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder={t('Search casino or slot ...') ?? ''}
                  />
                  {(filter.length || search?.length) && (
                    <button
                      onClick={() => {
                        setFilter('');
                        searchRef.current?.focus();
                      }}
                      className={styles.clearContainer}
                    >
                      <Icon.Close height={20} width={20} />
                      <span>{t('Clear')}</span>
                    </button>
                  )}
                </div>
                <ResultSearch
                  searchRef={searchRef}
                  searchText={debounceValue}
                  containerRef={containerRef}
                />
              </div>

              <div
                id={Analytics.general.headerSaved}
                className={styles.headerSave}
                onClick={isLoggedIn ? handleSaved : handleVisibleLoginModal}
              >
                <div className={clsx(styles.headerLink)}>
                  <Icon.Save />
                  <span>{t('Saved')}</span>
                </div>
              </div>

              <div id={Analytics.general.headerLogin} onClick={handleProfile}>
                <div
                  className={clsx({
                    [styles.headerLoginLink]: true,
                    [styles.headerUnLogged]: !isLoggedIn,
                  })}
                >
                  <Icon.User />
                  <span suppressHydrationWarning={true}>
                    {isLoggedIn ? user?.username || t('Log In') : t('Log In')}
                  </span>
                </div>
              </div>

              <RegionsModal />

              <LoginModal
                isVisible={isLoginVisible}
                onClose={handleCloseLoginModal}
                confirmOnClick={handleConfirmLoginModal}
              />
            </div>
          </div>
        </div>
      </header>
      <RegionAutodetect />
    </div>
  );
};
