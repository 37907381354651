import BeGambleAwareImage from 'assets/images/footer/logos/BeGambleAware.svg';
import GamCareImage from 'assets/images/footer/logos/GamCare.svg';
import GamStopImage from 'assets/images/footer/logos/GamStop.svg';

import { ICasinoReview } from './types';

export const VariantMenuItems = {
  Listen: 'listen',
  Download: 'download',
  Copy: 'copy',
  Smart: 'smart',
  Remove: 'remove',
};

export const cardListIcons = {
  bad: 'bad',
  good: 'good',
  cherry: 'cherry',
};

export const socialListIcons = {
  youtube: 'youtube',
  twitter: 'twitter',
  telegram: 'telegram',
  facebook: 'facebook',
};

export interface TemplatesModalTextI {
  id: number;
  path: string;
  title: string;
  subTitle: string;
}

export interface linksParams {
  id: number;
  path: string;
  text: string;
}

export interface slideSlotBigParams {
  id: number;
  img: string;
  alt: string;
}

export interface slideSlotParams {
  _id: number;
  imageUrl: string;
  altMain: string;
  imgFooter: string;
  altFooter: string;
  rating: string;
  name: string;
}

export interface slideSlotMiniParams {
  id: number;
  imageUrl: string;
  altMain: string;
  imgFooter: string;
  altFooter: string;
  rating: string;
  name: string;
}

export interface postsContentParams {
  id: number;
  img: string;
  date: string;
  text: string;
}

export interface sliderContentParams {
  id: number;
  img: string;
  text: string;
}

export interface footerParams {
  id: number;
  link: string;
  icon?: string;
  text: string;
}

export interface reviesUser {
  id: number;
  img: string;
  alt: string;
  name: string;
  inspections: string;
  percentages: string;
  rated: string;
}

export interface SlotInfoBlockParams {
  id: number | string;
  text: string;
  link?: string;
  value: string | number;
}

export interface SlotActionBlockParams {
  id: number | string;
  anchorId: string | number;
  text: string;
  icon: string;
  onClick?: () => void;
}

export interface descriptionBigWinsViewerParams {
  title: string;
  text: string;
}

export interface Casino {
  id: number;
  name: string;
}

export interface CommetUser {
  id: number;
  date: string;
  like: number;
  img?: string;
  dislikes: number;
  comment: string;
  casino: Casino;
  recommended: boolean;
}

export interface ReviewSlotParams {
  id: number;
  img?: string;
  name: string;
  date: string;
  percent: string;
}

export interface CasinoInspectionsProps {
  id: number | string;
  url?: string;
  name: string;
  icon: string;
  count: string;
  description: string;
}

export interface dataLoginModalProps {
  id: number;
  icon: string;
  text: string;
}

export const dataCasinoInspections: CasinoInspectionsProps[] = [
  {
    id: 0,
    name: 'Slot Paradise',
    icon: 'comment',
    count: '202',
    description: 'casino reviews',
  },
  {
    id: 1,
    name: 'Slot Paradise',
    icon: 'search',
    count: '35',
    description: 'slots RTP reviews',
  },
  {
    id: 2,
    name: 'Slot Paradise',
    icon: 'trophy',
    count: '74',
    description: 'slots big wins',
  },
];

export const dataReviewSlot: ReviewSlotParams[] = [
  {
    id: 0,
    name: 'Legacy Of Inca',
    date: '10 Jun, 2023',
    percent: '97.53%',
  },
  {
    id: 1,
    name: 'Phoenix Reborn',
    date: '5 Jun, 2023',
    percent: '90.16%',
  },
];

export const dataCommentUser: ICasinoReview[] = [
  {
    _id: '0',
    likes: 67,
    dislikes: 2,
    review:
      'The Lucky Jackpot Casino is a hidden gem. The moment I entered, I was greeted by a friendly and welcoming staff. The casino boasts an extensive collection of slots, and the payouts were frequent and generous. The live dealer games added a thrilling touch to my experience. The on-site dining options were excellent, and the overall atmosphere was lively and energetic. I can wait to visit again!',
    recommended: true,
    casino_id: '0',
    __v: 0,
    user_id: '0',
    createdAt: '432432',
    updatedAt: '432432',
  },
  {
    _id: '0',
    likes: 0,
    dislikes: 54,
    review:
      'Worst online casino ever! They stole my money and their customer service is so rude! I furious! Avoid at all cost!',
    recommended: false,
    casino_id: '0',
    __v: 0,
    user_id: '0',
    createdAt: '432432',
    updatedAt: '432432',
  },
];

export const descriptionBigWinsViewer: descriptionBigWinsViewerParams = {
  title: 'Slot Colt Lightning Big Win at Slot Paradise',
  text: 'Slot Paradise is een online casino van Cyscuti Entertainment. Deze groep omvat verschillende goksites onder eenzelfde Kahnawake vergunning. Je kunt er online gokken op een erg gevarieerd spelaanbod met gokkasten, tafels en live casino spellen.',
};

export const logos = [
  {
    id: 0,
    img: GamCareImage,
    alt: 'GamCareImage',
    link: 'https://www.gamcare.org.uk/',
  },
  {
    id: 1,
    img: BeGambleAwareImage,
    alt: 'BeGambleAwareImage',
    link: 'https://www.begambleaware.org/',
  },
  {
    id: 2,
    img: GamStopImage,
    alt: 'GamStopImage',
    link: 'https://www.gamstop.co.uk/',
  },
];

export const listThree = [
  {
    id: 0,
    link: '/privacy',
    text: 'Privacy Policy',
  },
  {
    id: 1,
    link: '/terms',
    text: 'Terms And Conditions',
  },
  {
    id: 2,
    link: '/cookie',
    text: 'Cookie Policy',
  },
  {
    id: 3,
    link: '/responsible-gaming',
    text: 'Responsible Gaming',
  },
];

export const socials = [
  {
    id: 1,
    link: 'https://facebook.com/rtpiinfo',
    icon: socialListIcons.facebook,
    text: 'Facebook',
  },
  {
    id: 2,
    link: 'https://t.me/rtpiinfo',
    icon: socialListIcons.telegram,
    text: 'Telegram',
  },
];

export const links: linksParams[] = [
  {
    id: 0,
    path: '/home',
    text: 'Home',
  },
  {
    id: 1,
    path: '/login',
    text: 'LogIn',
  },
  {
    id: 2,
    path: '/casino-top',
    text: 'Casino On-Top',
  },
  {
    id: 3,
    path: '/casino-good',
    text: 'Casino Good',
  },
  {
    id: 4,
    path: '/casino-bad',
    text: 'Casino Bad',
  },
  {
    id: 5,
    path: '/slot-review',
    text: 'Review - RTP Slot',
  },
  {
    id: 6,
    path: '/casino-review',
    text: 'Review - Casino',
  },
  {
    id: 7,
    path: '/slots',
    text: 'Slot - Page',
  },
  {
    id: 8,
    path: '/bigwins-gallery',
    text: 'BigWins Gallery',
  },
  {
    id: 9,
    path: '/blog',
    text: 'Blog',
  },
  {
    id: 10,
    path: '/blog-post',
    text: 'Blog Post',
  },
  {
    id: 11,
    path: '/casinos',
    text: 'Collection - Casino',
  },
  {
    id: 12,
    path: '/slots',
    text: 'Collection - Slot',
  },
  {
    id: 13,
    path: '/collection-bigwins',
    text: 'Collection - BigWins',
  },
  {
    id: 14,
    path: '/profile',
    text: 'Profile',
  },
  {
    id: 15,
    path: '/profile-empty',
    text: 'Profile Empty',
  },
  {
    id: 16,
    path: '/profile-public',
    text: 'Profile Public',
  },
];

export const dataLoginModal: dataLoginModalProps[] = [
  {
    id: 0,
    icon: 'save',
    text: 'Save your favorites',
  },
  {
    id: 1,
    icon: 'search',
    text: 'Inspect casinos',
  },
  {
    id: 2,
    icon: 'trade',
    text: 'Follow RTP changes',
  },
];
