import { Routes } from 'constants/routes';
import { useRouter } from 'next/router';
import { useState } from 'react';

export const useLoginModal = () => {
  const router = useRouter();

  const [isLoginVisible, setIsLoginVisible] = useState<boolean>(false);

  const handleCloseLoginModal = () => {
    setIsLoginVisible(false);
  };

  const handleVisibleLoginModal = () => {
    setIsLoginVisible(true);
  };

  const handleConfirmLoginModal = () => {
    router.push(Routes.login);
  };

  return {
    isLoginVisible,
    handleCloseLoginModal,
    handleVisibleLoginModal,
    handleConfirmLoginModal,
  };
};
