import React from 'react';
import styles from './styles.module.css';
import clsx from 'clsx';
import Link from 'next/link';
import { Icon } from 'common/icon';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  url: string;
  count: number;
  width?: string;
  type?: 'medium' | 'small' | 'box';
}

export const SeeMoreCard = ({
  url,
  count,
  width,
  type = 'medium',
  ...props
}: Props) => {
  return (
    <div style={{ position: 'relative' }} {...props}>
      {type !== 'box' && (
        <div
          className={clsx({
            [styles.separator]: true,
            [styles.separatorSmall]: type === 'small',
          })}
        />
      )}

      <Link href={url}>
        <div
          style={width ? { width } : {}}
          className={clsx({
            [styles.seeMoreCardContainer]: true,
            [styles.seeMoreCardContainerSmall]: type === 'small',
            [styles.seeMoreCardContainerBox]: type === 'box',
          })}
        >
          <div className={styles.infoContainer}>
            <div className={styles.iconContainer}>
              <Icon.Menu height={20} width={20} />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.moreText}>{`${count} more`}</div>
            </div>
          </div>

          {type !== 'box' && (
            <div className={styles.linkContainer}>
              <div className={styles.linkText}>open collection</div>
              <div className={styles.arrowIconContainer}>
                <Icon.Arrow />
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};
