import { BASE_URL } from 'api/constants';
import { BasePageProps, UseQueryReturnProps } from '../types';

import { filterEmptyFields } from './object';

export const addParamsToUrl = (
  url: string,
  params?: Record<string, any>
): string => {
  if (!params) return url;
  const urlParams = new URLSearchParams();
  Object.keys(filterEmptyFields(params)).forEach((key) =>
    urlParams.append(key, params[key])
  );
  return `${url}?${urlParams.toString()}`;
};

export const mapQuery = ({
  error,
  isFetching,
  ...props
}: UseQueryReturnProps): BasePageProps => {
  return {
    ...props,
    error: error?.message,
    isLoading: isFetching,
  };
};

export const getCurrentSearchQuery = () => {
  if (typeof window !== 'undefined') {
    return window?.location?.href;
  }
  return null;
};

const addNewlineOnLimit = (inputString: string, charLimit: number) => {
  let result = '';
  let currentLineLength = 0;

  for (let i = 0; i < inputString.length; i++) {
    if (currentLineLength >= charLimit && inputString[i] === ' ') {
      result += '\n';
      currentLineLength = 0;
    } else {
      result += inputString[i];
    }

    currentLineLength++;
  }

  return result?.trim();
};

export const getOpenGraphImage = ({
  name,
  rating,
  image,
  logo,
  type = 'default',
}: {
  name?: string;
  rating?: string;
  image?: string;
  logo?: string;
  type?: string;
}) => {
  const params = new URLSearchParams();
  if (image) {
    params.append('image', encodeURIComponent(image));
  }

  if (name) {
    switch (type) {
      case 'post':
        params.append(
          'title',
          encodeURIComponent(addNewlineOnLimit(name, 20).trim())
        );
        break;
      default:
        params.append('title', name);
    }
  }

  if (rating) {
    params.append('rtpi', rating);
  }

  if (logo) {
    params.append('logo', logo);
  }

  params.append('type', type ?? 'default');

  return `${BASE_URL}generateImage.jpg?${params.toString()}`;
};
