import { useEffect } from 'react';

import { useAppDispatch } from 'store';
import { setLoading } from 'store/slices/loader';

export const useLoader = (show: boolean) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoading(show));
  }, [show]);
};
