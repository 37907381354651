import { SITE_URL } from 'api/constants';
import Head from 'next/head';
import { getCurrentSearchQuery, getOpenGraphImage } from 'utils/query';

interface OpenGraphProps {
  type?: string;
  title?: string;
  image?: string;
  locale?: string;
  siteName?: string;
  url?: string | null;
  description?: string;
}

interface CustomOpenGraphProps {
  titlePage?: string;
  canonicalUrl?: string;
  descriptionPage?: string;
  metadata: OpenGraphProps;
}

export const CustomMetadata = ({
  metadata,
  titlePage,
  canonicalUrl,
  descriptionPage,
}: CustomOpenGraphProps) => {
  const {
    title,
    description,
    locale = 'en_US',
    type = 'website',
    siteName = 'RTPi Casino',
    url = getCurrentSearchQuery(),
    image = getOpenGraphImage({}),
  } = metadata;

  if (!title || title === 'undefined') {
    return null;
  }

  return (
    <Head>
      <title>{titlePage}</title>
      <meta name="description" content={descriptionPage} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {url && <meta property="og:url" content={url} />}
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="600" />
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content={type} />

      {canonicalUrl && (
        <link rel="canonical" href={`${SITE_URL}${canonicalUrl}`} />
      )}
    </Head>
  );
};
