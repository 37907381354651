import { Routes } from 'constants/routes';
import { Icon } from 'common/icon';

interface MenuListItem {
  id: number;
  alt: string;
  title: string;
  type: 'posts' | 'casinos' | 'slots';
  icon: JSX.Element;
}

interface MenuFooterListItem {
  isNew?: boolean;
  path: string;
  title: string;
  icon: JSX.Element;
}

export const menuList: MenuListItem[] = [
  {
    id: 0,
    alt: 'read',
    type: 'posts',
    title: 'Read',
    icon: <Icon.Casinos />,
  },
  {
    id: 1,
    alt: 'casinos',
    type: 'casinos',
    title: 'Casinos',
    icon: <Icon.Slot />,
  },
  {
    id: 2,
    type: 'slots',
    alt: 'Slot-rtp-reviews',
    title: 'Slot RTP reviews',
    icon: <Icon.About />,
  },
];
export const menuFooterList: MenuFooterListItem[] = [
  { title: 'Blog', path: Routes.blogPosts, icon: <Icon.About /> },
  { title: 'Guides', path: Routes.guides, icon: <Icon.Guide />, isNew: true },
  { title: 'About us', path: Routes.aboutUs, icon: <Icon.About /> },
  { title: 'Contact us', path: Routes.contactUs, icon: <Icon.Comment /> },
];
