import clsx from 'clsx';
import { RefObject, useMemo } from 'react';

import { ICasinoCollection, ISlotCollection } from 'constants/types';
import { useOpenTriggerState } from 'hooks/useOpenTrigger';
import { useCasinoCollectionsQuery } from 'store/api/casino_collection';
import { useSlotCollectionsQuery } from 'store/api/slot_collection';

import { menuList } from './config';

import { Routes } from 'constants/routes';

import { MenuFooter } from './footer';
import { MenuLinkItem } from './item';
import { MenuPostLinkItem } from './post-item';
import styles from './styles.module.css';
import { MenuTitle } from './title';
import { usePostsQuery } from 'store/api/blog_post';
import { shuffle } from 'lodash';
import { isMobile } from 'hooks';
import { MenuProviders } from './providers';

export interface Props {
  state?: useOpenTriggerState;
  triggerRef?: RefObject<any>;
}

export const MenuContent = ({ state }: Props) => {
  const mobile = isMobile();

  let { isOpen, close } = state as useOpenTriggerState;

  const placeholdersArray = Array.from({ length: 10 }).map((_, index) => ({
    _id: index?.toString(),
    name: 'Loading...',
    isVisible: true,
  }));

  const placeholdersPostsArray = Array.from({ length: 2 }).map((_, index) => ({
    id: index?.toString(),
    title: 'Loading...',
  }));

  const { data: { results: casinosCollections } = {} } =
    useCasinoCollectionsQuery();
  const { data: { results: slotsCollections } = {} } =
    useSlotCollectionsQuery();

  const { data: { results: allPosts } = {} } = usePostsQuery({});

  const posts = useMemo(() => {
    if (allPosts?.length) {
      return shuffle(allPosts).splice(0, 2);
    }
  }, [allPosts]);

  const menuBodyClasses = clsx(styles.menuBody, {
    [styles.menuBodyActive]: isOpen,
  });

  return (
    <nav>
      <div className={menuBodyClasses}>
        <div className={styles.container}>
          {menuList
            .filter((item) => {
              if (mobile) {
                return item.type !== 'posts';
              }
              return true;
            })
            .map((item, index) => (
              <div key={item.id.toString() + index} className={styles.menuList}>
                <MenuTitle {...item} />

                <ul className={styles.wrapper_list}>
                  {item.type === 'casinos' && (
                    <MenuLinkItem
                      type="casino"
                      item={
                        {
                          name: 'All Casinos',
                        } as ICasinoCollection
                      }
                      close={close}
                      customUrl={Routes.allCasinos}
                    />
                  )}

                  {item.type === 'casinos' &&
                    (casinosCollections ?? placeholdersArray)
                      ?.filter((it) => it.isVisible)
                      ?.map((link, idx) => (
                        <MenuLinkItem
                          type="casino"
                          key={link?.toString() + idx}
                          item={link}
                          close={close}
                        />
                      ))}

                  {item.type === 'slots' && (
                    <MenuLinkItem
                      type="slot"
                      close={close}
                      customUrl={Routes.allSlots}
                      item={{ name: 'All Slots' } as ISlotCollection}
                    />
                  )}

                  {item.type === 'slots' &&
                    (slotsCollections ?? placeholdersArray)
                      ?.filter((it) => it.isVisible)
                      ?.map((link, idx) => (
                        <MenuLinkItem
                          type="slot"
                          key={link?.toString() + idx}
                          item={link}
                          close={close}
                        />
                      ))}

                  {item.type === 'posts' &&
                    (posts ?? placeholdersPostsArray)?.map((it, idx) => (
                      <MenuPostLinkItem
                        item={it}
                        close={close}
                        key={it.title?.toString() + idx}
                      />
                    ))}
                </ul>
              </div>
            ))}
        </div>
        <MenuProviders close={close} />
        <MenuFooter close={close} />
      </div>
    </nav>
  );
};
