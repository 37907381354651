import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { IBlogPost } from 'constants/types';
import Image from 'next/image';
import Link from 'next/link';
import { Routes } from 'constants/routes';

export const MenuPostLinkItem = ({
  item,
  close,
}: {
  close: () => void;
  item:
    | IBlogPost
    | { title: string; id: string; slug?: string; imageUrl?: string };
}) => {
  const [Content, setContent] = useState<undefined | JSX.Element>();

  useEffect(() => {
    setContent(
      <>
        {item.imageUrl && (
          <Image
            className={styles.image}
            width={120}
            height={100}
            src={item.imageUrl}
            alt={item.title}
          />
        )}
        {!item.imageUrl && <div className={styles.imagePlaceholder} />}
        <div className={styles.textContainer}>
          <div className={styles.text}>{item.title}</div>
        </div>
      </>
    );
  }, []);

  return (
    <Link
      onClick={close}
      href={Routes.blogPage(item?.slug ?? '')}
      className={styles.container}
    >
      {Content}
    </Link>
  );
};
