import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Search } from 'components/search';

import { ICasino, IProvider, ISlot } from 'constants/types';
import { useClickOutside } from 'hooks/useClickOutside';
import { useFocus } from 'hooks/useFocus';
import { useRouter } from 'next/router';
import { useSearchQuery } from 'store/api/search';
import { Loader } from 'common';

import styles from './styles.module.css';

interface ResultSearchProps {
  searchText?: string;
  searchRef: React.RefObject<HTMLInputElement>;
  containerRef?: React.RefObject<HTMLDivElement>;
}

export const ResultSearch = ({
  searchRef,
  searchText,
  containerRef,
}: ResultSearchProps) => {
  const router = useRouter();
  const search = router.query.q
    ? String(decodeURIComponent(router.query.q?.toString()))
    : searchText ?? '';

  const ref = useRef(null);
  const [isResult, setIsResult] = useState(false);

  const handleCloseResult = () => {
    setIsResult(false);
  };

  useClickOutside({ onClick: handleCloseResult, ref, targetRef: containerRef });

  const { data, isFetching } = useSearchQuery(
    {
      searchQuery: search.toLowerCase(),
    },
    { skip: !search }
  );

  const focused = useFocus(searchRef);

  const results: Array<ICasino | ISlot | IProvider> = useMemo(() => {
    if (search) {
      return [
        ...(data?.results?.casinos ?? []),
        ...(data?.results?.slots ?? []),
        ...(data?.results?.providers ?? []),
      ];
    } else {
      return [];
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (focused) {
      setIsResult(true);
    }
  }, [focused]);

  return (
    <>
      {isFetching && (
        <div ref={ref} className={styles.loader}>
          <Loader />
        </div>
      )}
      {isResult && search && (
        <div ref={ref} className="search-results">
          <Search data={results} handleClose={handleCloseResult} />
        </div>
      )}
    </>
  );
};
