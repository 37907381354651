import React from 'react';

import { Header } from 'components/header';
import { Footer } from 'components/footer';

import styles from './styles.module.css';

interface LayoutProps {
  children: React.ReactNode;
  hasFooter?: boolean;
  isMain?: boolean;
}

export const Layout = ({
  children,
  isMain = false,
  hasFooter = false,
}: LayoutProps) => {
  return (
    <div className={styles.wrapper}>
      <Header isMain={isMain} />
      {children}
      {hasFooter && <Footer />}
    </div>
  );
};
