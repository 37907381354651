import { useWeightByNameQuery } from 'store/api/weights';

export const useRTPiWeights = () => {
  const {
    data: { value: RTPiMedium } = { value: 0 },
    isFetching: isRTPiMediumFetching,
  } = useWeightByNameQuery({
    name: 'RTPiMedium',
  });
  const {
    data: { value: RTPiHigh } = { value: 0 },
    isFetching: isRTPiHighFetching,
  } = useWeightByNameQuery({
    name: 'RTPiHigh',
  });

  const isBad = (value: number) => value < RTPiMedium;
  const isGood = (value: number) => value >= RTPiMedium && value < RTPiHigh;

  return {
    isBad,
    isGood,
    RTPiHigh: RTPiHigh ?? 91,
    RTPiMedium: RTPiMedium ?? 80,
    isFetching: isRTPiHighFetching || isRTPiMediumFetching,
  };
};
