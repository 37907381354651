import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  clearCasinosPagination,
  selectPaginationCasinos,
  selectPaginationCasinosOptions,
  setPaginationCasinosCurrentCollection,
  setPaginationCasinosCurrentRegion,
  useLazyPaginationCasinosQuery,
} from 'store/api/pagination';
import { useCurrentRegion } from './useCurrentRegion';

export const useCasinosPagination = ({
  skip,
  sortBy,
  collection,
}: {
  skip?: boolean;
  sortBy?: string;
  collection: string | string[] | undefined | null;
}) => {
  const dispatch = useAppDispatch();

  const limit = 12;

  const { isFetching: isCountriesFetching, currentRegion } = useCurrentRegion();

  const [fetchData, { data: { count = -1 } = {}, isFetching }] =
    useLazyPaginationCasinosQuery();

  const { currentCollection, currentRegion: lastRegion } = useSelector(
    selectPaginationCasinosOptions()
  );
  const data = useSelector(selectPaginationCasinos());

  const [page, setPage] = useState(
    data?.length ? Math.ceil(data?.length / limit) : 1
  );

  useEffect(() => {
    if (!skip) {
      if (
        currentCollection !== collection ||
        lastRegion !== currentRegion?._id
      ) {
        setPage(1);
        dispatch(clearCasinosPagination());
        dispatch(setPaginationCasinosCurrentCollection(collection));
        dispatch(setPaginationCasinosCurrentRegion(currentRegion?._id));
      }
    }
  }, [currentCollection, collection, currentRegion]);

  useEffect(() => {
    if (!skip) {
      const isCached = data?.length && Math.ceil(data?.length / limit) === page;

      if (!isCountriesFetching && !isCached) {
        if (currentRegion?._id === lastRegion) {
          fetchData({
            page,
            limit,
            sortBy,
            countries: currentRegion?._id,
            collections: collection?.toString() ?? '',
          });
        }
      }
    }
  }, [limit, page, currentCollection, isCountriesFetching, lastRegion]);

  const isLast = useMemo(() => {
    if (skip) {
      return true;
    }

    if (Number(count) === -1) {
      return false;
    }
    return data?.length >= Number(count);
  }, [count, data?.length]);

  return {
    page,
    data,
    isLast,
    setPage,
    isFetching,
    count: Number(count) < 0 ? 0 : count,
  };
};
