import { useCallback } from 'react';

export interface useOpenTriggerProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export interface useOpenTriggerState {
  isOpen: boolean;
  setOpen(isOpen: boolean): void;
  open(): void;
  close(): void;
  toggle(): void;
}

export function useOpenTrigger(
  props: useOpenTriggerProps
): useOpenTriggerState {
  let { isOpen, setOpen } = props;

  const open = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [setOpen, isOpen]);

  return {
    isOpen,
    setOpen,
    open,
    close,
    toggle,
  };
}
