import clsx from 'clsx';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/icon';

import { ModalMain } from '../main';
import { Button } from 'common/new-button';

import img from 'assets/images/bg-login.webp';
import { dataLoginModal, dataLoginModalProps } from 'constants/templates';

import styles from './styles.module.css';
import { useOnClickOutside } from 'usehooks-ts';
import { useRef } from 'react';
import { clearRedirectOptions } from 'utils/redirectOptions';
import { Analytics } from 'constants/analytics';

interface LoginModalProps {
  isVisible: boolean;
  onClose: () => void;
  confirmOnClick: () => void;
}

const variantsIcon = {
  save: 'save',
  trade: 'trade',
  search: 'search',
};

const AdvantagesItem = ({ icon, text }: { icon: string; text: string }) => {
  const { t } = useTranslation();

  const renderIcon = (i: string) => {
    switch (i) {
      case variantsIcon.save:
        return <Icon.Save />;
      case variantsIcon.search:
        return <Icon.Search />;
      case variantsIcon.trade:
        return <Icon.TradeArrow fill="white" />;

      default:
        return null;
    }
  };

  return (
    <div className={styles.advantage}>
      <div className={styles.icon}>{renderIcon(icon)}</div>
      <span className={styles.text}>{t(text)}</span>
    </div>
  );
};

export const LoginModal = ({
  isVisible,
  onClose,
  confirmOnClick,
}: LoginModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(
    modalRef,
    () => {
      clearRedirectOptions();
      onClose();
    },
    'mouseup'
  );

  const classesCancel = clsx(styles.button, styles.cancel);
  const classesConfirm = clsx(styles.button, styles.confirm);

  return (
    <ModalMain
      title=""
      width={280}
      footer={null}
      targetRef={modalRef}
      isVisible={isVisible}
      closeOnClick={onClose}
    >
      <div className={styles.body}>
        <Image
          width={280}
          height={140}
          src={img}
          className={styles.img}
          alt="login-img"
        />

        <div className={styles.wrapper_content}>
          <div className={styles.wrapper_advantages}>
            {dataLoginModal?.map((item: dataLoginModalProps) => (
              <AdvantagesItem
                key={item.id.toString()}
                icon={item.icon}
                text={item.text}
              />
            ))}
          </div>
          <div className={styles.button_wrapper}>
            <Button
              id={Analytics.banner.loginLogin}
              text="Log in"
              onClick={() => {
                confirmOnClick();
                onClose();
              }}
              className={classesConfirm}
            />
            <Button
              id={Analytics.banner.loginCancel}
              text="Cancel"
              onClick={() => {
                clearRedirectOptions();
                onClose();
              }}
              className={classesCancel}
            />
          </div>
        </div>
      </div>
    </ModalMain>
  );
};
