import { useEffect, useState } from 'react';

export const useFocus = (
  ref: React.RefObject<HTMLInputElement>,
  defaultState = false
) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const onFocus = () => setState(true);
    const onBlur = () => setState(false);

    if (ref.current) {
      ref.current.addEventListener('focus', onFocus);
      ref.current.addEventListener('blur', onBlur);

      return () => {
        ref.current?.removeEventListener('focus', onFocus);
        ref.current?.removeEventListener('blur', onBlur);
      };
    }
  }, [ref]);

  return state;
};
