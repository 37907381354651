import { useEffect } from 'react';
import {
  clearRedirectOptions,
  getRedirectOptions,
} from 'utils/redirectOptions';

type Props = {
  actions: {
    name: string;
    action: () => void;
  }[];
};

export const useRedirectActions = ({ actions }: Props) => {
  useEffect(() => {
    const redirectOptions = getRedirectOptions();

    actions.forEach((item) => {
      if (redirectOptions?.action === item.name) {
        clearRedirectOptions();
        if (item.action) {
          item?.action();
        }
      }
    });
  }, []);
};
