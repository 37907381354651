import Image from 'next/image';
import Link from 'next/link';

import { Routes } from 'constants/routes';
import { IProvider } from 'constants/types';

import { getItemIdentifier } from 'utils/slug';

import styles from './styles.module.css';
import React from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  key: string;
  data: IProvider;
}

export const ProviderCard = ({ key, data, ...props }: Props) => {
  return (
    <div key={key} className={styles.container_provider} {...props}>
      <Link key={data._id} href={Routes.provider(getItemIdentifier(data))}>
        <div className={styles.wrapper_provider}>
          <div className={styles.image}>
            <Image
              width={160}
              height={50}
              alt={data?.name}
              src={data?.imageUrl ?? ''}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
