import { useProvidersQuery } from 'store/api/provider';

export const useProviders = () => {
  const { data: { results = [], count } = {}, isFetching } =
    useProvidersQuery();

  return {
    count,
    results,
    isFetching,
  };
};
