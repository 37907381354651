import React from 'react';
import styles from './styles.module.css';
import { menuFooterList } from '../config';
import Link from 'next/link';

export const MenuFooter = ({ close }: { close: () => void }) => {
  return (
    <div className={styles.container}>
      {menuFooterList.map((item, index) => {
        const isLast = menuFooterList?.length - 1 === index;
        return (
          <>
            <Link
              onClick={close}
              href={item.path}
              className={styles.itemContainer}
            >
              <div className={styles.iconContainer}>{item.icon}</div>
              <div className={styles.text}>{item.title}</div>
              {item.isNew && <div className={styles.newTag}>new</div>}
            </Link>

            {!isLast && <div className={styles.separator}></div>}
          </>
        );
      })}
    </div>
  );
};
