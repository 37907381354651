import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOpenTrigger } from 'hooks/useOpenTrigger';
import { MenuContent } from './content';

import { Icon } from 'common/icon';
import { useClickOutside } from 'hooks/useClickOutside';

import styles from './styles.module.css';

export const Menu = () => {
  const ref = useRef(null);
  const menuRef = useRef(null);

  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const state = useOpenTrigger({ isOpen, setOpen });

  const handleClose = () => {
    setOpen(false);
  };

  useClickOutside({ onClick: handleClose, ref: menuRef });

  const renderIcon = isOpen ? (
    <Icon.Close width={20} height={20} />
  ) : (
    <Icon.Menu />
  );

  return (
    <div ref={menuRef} className={styles.wrapper_menu}>
      <div className={styles.menu__btn} onClick={state.toggle}>
        {renderIcon}
        <div
          className={clsx(styles.menu__btn_open, {
            [styles.menu__btn_open_active]: isOpen,
          })}
        >
          <span>{t('Menu')}</span>
        </div>
        <div
          className={clsx(styles.menu__btn_close, {
            [styles.menu__btn_close_active]: isOpen,
          })}
        >
          <span>{t('Close')}</span>
        </div>
      </div>
      <MenuContent state={state} triggerRef={ref} />
    </div>
  );
};
