import axios from 'axios';

export async function getCountryByIP() {
  try {
    const ipResponse = await axios.get('https://api.ipify.org?format=json');
    const ip = ipResponse.data.ip;

    const countryResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
    return countryResponse.data;
  } catch (error) {
    console.error(error);
  }
}
